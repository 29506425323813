/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.logo {
    background: url('./assets/img/kickapiLogo.jpg') no-repeat left center;
    width: 200px;
    height: 100px;
    padding-left: 30px;
    padding-right: 30px;

    background-size: contain;
 }
.classModal::part(content){
    --border-radius: 50px 5px 50px 5px;
  }
  .classModalEm::part(content){
    --border-radius: 20px 0px 20px 0px;
    width: 30vw;
    height: 40vh;
    // max-width: 500px;
    // max-height: 300px;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  }

  .label-Title{
    font-size: x-large;
    --background: var(--ion-color-primary-button);
    font-weight: bold;
    text-align: justify;
    color: white;
}

.label-title-grid{
font-size:medium;
--background: var(--ion-color-primary-button);
font-weight:bold ;
text-align: justify;
color: rgba(18, 46, 3, 0.598);
}
.label-grid-small{
  font-size:smaller;
  --background: var(--ion-color-primary-button);
  font-weight:normal ;
  text-align: justify;
  color: rgb(120, 118, 118);
  }

.label-grid{
  font-size:normal;
  --background: var(--ion-color-primary-button);
  font-weight:normal ;
  text-align: justify;
  color: white;
  }
  


.Box-Card-Button-Grid {
    font-size: smaller;
    --background: var(--ion-color-primary-button);
    font-weight: normal;
    text-align-last: inherit;
    color: white;
    display: -webkit-inline-box;
}
.Box-Card-Button {
  // /* font-size: large; */
  // --background: var(--ion-color-primary-button);
  // /* font-weight: bold; */
  // /* text-align-last: inherit; */
  // /* color: white; */
  // left: 25px;
  // top: 17px;
  // /* position: absolute; */
  // /* overflow: visible; */
  // /* width: 88px; */
  // white-space: nowrap;
  // text-align: left;
  // font-family: Roboto;
  // font-style: normal;
  // font-weight: normal;
  // font-size: 11px;
  // color: rgba(0,0,0,1);

  --border-radius: 10px;
  font-size: small;
  --background: var(--ion-color-primary-button);
  font-weight: bold;
  text-align-last: center;
  color: white;
  display: -webkit-inline-box;
 }

 .Box-Card-ButtonAtencao{
  --border-radius: 10px;
  font-size: medium;
  --background: var(--ion-color-primary-button-atencao);
  font-weight: bold;
  text-align-last: inherit;
  color: white;
  display: -webkit-inline-box;
 }

.label-row{
  font-size:medium;
  --background: var(--ion-color-primary-button);
  font-weight:normal ;
  text-align: justify;
  color: white;
  }

.classModaleditPoint::part(content){
    --border-radius: 10px;
    width: 90%;
    height: 95%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }

  .classModaleditDataSource::part(content){
    --border-radius: 10px;
    max-width: 70%;
    max-height: 70%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }
  .classModaleditUsuario::part(content){
    --border-radius: 10px;
    max-width: 70%;
    max-height: 70%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }
  .classModaleditCliente::part(content){
    --border-radius: 10px;
    max-width: 70%;
    max-height: 70%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }
  .classModaleditRegrasSec::part(content){
    --border-radius: 10px;
    max-width: 80%;
    max-height: 100%; 
    height: 80vh;
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }
  .classModaleditperfilSec::part(content){
    --border-radius: 10px;
    max-width: 80%;
    max-height: 100%; 
    height: 80vh;
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }
  .classModaleditperfilCliente::part(content){
    --border-radius: 10px;
    max-width: 70%;
    max-height: 70%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }

  .classModaleditInstancia::part(content){
    --border-radius: 10px;
    max-width: 70%;
    max-height: 70%; 
    background-color: var( --ion-color-primary-background-modal);
    border: 2px solid var(--ion-color-primary-borda);
  }


  .input{
    border-radius: 10px;
    //height: 80%;
    color:gray;

  }
  .label-select{
    font-size: small;
  }

  ion-button{
      --border-radius: 4px;
  
  }
// ******   Hint *******

  // [data-message] {
  //   position: relative;
  // }
  
  // [data-message]:hover:after {
  //   opacity: 1;
  //   transition: all .3s ease;
  //   bottom: 25px;
  //   left: 0;
  // }
  


  // [data-message]:after {
  //   content: attr(data-message);
  //   position: absolute;
  //   left: 0;
  //   bottom: 20px;
  //   font-size: .8em;
  //   opacity: 0;
  //   padding: 5px 10px;
  //   background: rgba(0,0,0, .8);
  //   border-radius: 5px;
  //   transition: all .3s ease;
  //   white-space: nowrap;
  //   color: #fff;
  // }
  
  // [data-message]:before {
  //   height: 0;
  //   width: 0;
  //   content: '';
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent; 
  //   border-top: 5px solid rgba(0,0,0, .8);
  //   position: absolute;
  //   left: 5px;
  //   opacity: 0;
  //   bottom: 15px;
  //   transition: all .3s ease;
  // }
  
  // [data-message]:hover:before {
  //   opacity: 1;
  //   bottom: 20px;
  //   transition: all .3s ease;
  // }
  // ****** Fim  Hint *******


  //******** PopOver Menu *********
ion-popover {
    --background: rgba(40, 173, 218, 0.6);
    --backdrop-opacity: 0.6;
    --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
    --color: white;
    --width: 10%;
  }
  
ion-popover ion-content {
    --background: rgba(40, 173, 218, 0.6);
    
  }
  
ion-popover::part(backdrop) {
    background-color: rgb(175, 178, 206);
  }

  //******** Fim PopOver Menu *********
  @import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/idea.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';


.ion-toolbar::part(backdrop) {
  background: rgba(123, 127, 123, 0.5);
}

#titulomenu  {
  --background: #287374;
  color: #fff;
}
ion-icon{
  color:white;
  cursor: pointer;
}